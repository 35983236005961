import Echo from 'laravel-echo'
import authHeader from '../auth/auth-header'

import io from "socket.io-client"
import Vue from "vue";
window.io = io

let port = '6001'
if (process.env.VUE_APP_SOCKET_PORT) {
  port = process.env.VUE_APP_SOCKET_PORT
}

const echoInstance = new Echo({
  broadcaster: "socket.io",
  host: process.env.VUE_APP_SOCKET_BACKEND_URL + `:${port}`,
  key: process.env.VUE_APP_LARAVEL_ECHO_SERVER_KEY,
  authEndpoint: process.env.VUE_APP_SOCKET_BACKEND_URL + '/broadcasting/auth',
  auth: {
    headers: authHeader()
  },
})

Vue.prototype.$echo = echoInstance
