import axiosIns from '/src/libs/axios'

class ManagerService {
  getManagers(data) {
    return axiosIns.get('manager', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  createManager(data) {
    return axiosIns.post('manager', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  updateManager(id, data) {
    return axiosIns.patch(`manager/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  changeStatus(data) {
    return axiosIns.patch('change-manager-status', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new ManagerService();
