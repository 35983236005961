import ManagerService from '../services/ManagerService'

export default {
  namespaced: true,

  state: {
    managers: [],
    pagination: {},
    newManagerMarker: false
  },

  actions: {
    getManagers({ commit }, data) {
      return ManagerService.getManagers(data)
        .then(response => {
          commit('setAll', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    createManager({ dispatch, getters, commit }, data) {
      return ManagerService.createManager(data)
        .then(response => {
          commit('setNewManagerMarker')
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateManager({ dispatch, getters }, payload) {
      return ManagerService.updateManager(payload.id, payload.data)
        .then(response => {
          dispatch('getManagers', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeStatus({ commit }, data) {
      return ManagerService.changeStatus(data)
        .then(response => {
          commit('updateStatus', data)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setAll(state, managers) {
      state.managers = managers
    },

    updateStatus(state, manager) {
      state.managers = state.managers.map(el => {
        if (el.id === manager.id) {
          el.status = manager.status
          return el
        }

        return el
      })
    },

    setPagination(state, page){
      state.pagination = page;
    },

    setNewManagerMarker(state) {
      state.newManagerMarker = ! state.newManagerMarker
    }
  },

  getters: {
    managers(state) {
      return state.managers
    },

    pagination(state){
      return state.pagination;
    },

    newManagerMarker(state) {
      return state.newManagerMarker
    }
  }
}
