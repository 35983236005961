export default [
  {
    path: '/auth/login',
    name: 'auth-login-v1',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/check-code',
    name: 'auth-check-code',
    component: () => import('@/views/auth/2FA.vue'),
    meta: {
      layout: '2FA',
      resource: 'Auth',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/auth/ForgotPassword'),
    props: (route) => ({ userEmail: route.query.email }),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
