import Vue from 'vue'
import axios from 'axios'
import router from '/src/router'
import AuthService from '@/auth/AuthService'
import authHeader from '../auth/auth-header'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
})

axiosIns.interceptors.request.use(function (config) {
  config.headers = authHeader()
  return config
})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const { response } = error
            if(response.data === "Session expired") {
        AuthService.logout();
        router.push({ name: 'auth-login-v1' });
    }
    if (
      response.status === 401 &&
      response.data === 'Unauthorized'
    ) {
      router.push({ name: 'access-denied' })
    }

    if (
      response.status === 401 &&
      response.data === 'Check 2FA'
    ) {
      router.push({ name: 'auth-check-code' })
    }

    if (
      response.status === 401 &&
      response.data.message === "Unauthenticated."
    ) {
      AuthService.logout()
      router.push({ name: 'auth-login-v1' })
    }

    if (
      response.status === 401 &&
      response.data === 'Force logout'
    ) {
      AuthService.logout()
      router.push({ name: 'auth-login-v1' })
    }

    return Promise.reject(error)
  }
)

// The serializer is needed to ensure that Axios 1.x generates requests in the same way as it did in version 0.x.
axiosIns.defaults.paramsSerializer = params => {
  const encodeArray = key => params[key].map((v) => `${key}[]=${encodeURIComponent(v)}`).join("&");

  return Object.keys(params)
  .filter(key => params[key] !== undefined && params[key] !== null && (!Array.isArray(params[key]) || params[key].length > 0))
  .map(key => {
    if (Object.prototype.toString.call(params[key]) === "[object Object]")  params[key] = JSON.stringify(params[key]);;
    if (Object.prototype.toString.call(params[key]) === "[object Array]") {
      if (params[key].length === 0) return undefined;
      if (Object.prototype.toString.call(params[key][0]) === "[object Number]") return encodeArray(key)
      params[key] = [JSON.stringify(...params[key])];
    }
    if (Array.isArray(params[key])) return encodeArray(key)
    return `${key}=${encodeURIComponent(params[key])}`;
  })
  .join("&");
}


Vue.prototype.$http = axiosIns

export default axiosIns
