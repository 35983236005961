import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin,
  CollapsePlugin,
  TooltipPlugin,
  FormCheckboxPlugin, SidebarPlugin, ListGroupPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueNativeNotification from 'vue-native-notification'
import 'vue2-datepicker/index.css';

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Socket
import '@/libs/socket'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import { CardPlugin } from 'bootstrap-vue'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(TooltipPlugin)
Vue.use(CollapsePlugin)
Vue.use(SidebarPlugin)
Vue.use(CardPlugin)
Vue.use(ListGroupPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import vue-good-table styles
require('@core/scss/vue/libs/vue-good-table.scss')

Vue.config.productionTip = false

Vue.use(VueNativeNotification, {
  requestOnNotify: true
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
