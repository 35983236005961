import axiosIns from "@axios"

class ActivityLogService {
  getLogs(data) {
    return axiosIns.get('activity-logs', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new ActivityLogService()
