import UserService from '../services/UserService'

export default {
  namespaced: true,

  state: {
    users: [],
    pagination: {},
    newUserMarker: false
  },

  actions: {
    getUsers({ commit }, data) {
      return UserService.getUsers(data)
        .then(response => {
          commit('setAll', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    createUser({ dispatch, getters, commit }, data) {
      return UserService.createUser(data)
        .then(response => {
          commit('setNewUserMarker')
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateUser({ dispatch, getters }, payload) {
      return UserService.updateUser(payload.id, payload.data)
        .then(response => {
          dispatch('getUsers', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateUserName({ commit, dispatch }, data) {
      return UserService.updateUserName(data)
        .then(response => {
          commit('updateName', data)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeStatus({ commit }, data) {
      return UserService.changeStatus(data)
        .then(response => {
          commit('updateStatus', data)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setAll(state, users) {
      state.users = users
    },

    updateStatus(state, user) {
      state.users = state.users.map(el => {
        if (el.id === user.id) {
          el.status = user.status
          return el
        }

        return el
      })
    },

    updateName(state, user) {
      state.users = state.users.map(el => {
        if (el.id === user.id) {
          el.full_name = user.full_name
          return el
        }

        return el
      })
    },

    setPagination(state, page) {
      state.pagination = page
    },

    setNewUserMarker(state) {
      state.newUserMarker = ! state.newUserMarker
    }
  },

  getters: {
    users(state) {
      return state.users
    },

    pagination(state) {
      return state.pagination
    },

    newUserMarker(state) {
      return state.newUserMarker
    }
  }
}
