import axiosIns from '/src/libs/axios'

class LogService {
  getLogs(data) {
    return axiosIns.get('logs', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new LogService()
