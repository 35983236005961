import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth.module'
import company from './company.module'
import manager from './manager.module'
import teams from './teams.module'
import user from './user.module'
import request from './request.module'
import log from './log.module'
import request_tabs from './request_tabs.module'
import schedule from './schedule.module'
import rule_book from './rule_book.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    company,
    manager,
    teams,
    user,
    request,
    log,
    request_tabs,
    schedule,
    rule_book,
  },
  strict: process.env.DEV,
})
