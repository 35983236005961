<template>
  <v-select
      v-model="selected"
      multiple
      :placeholder="placeholder"
      label="name"
      class="custom-filter"
      :class="className"
      :options="items"
      :closeOnSelect="false"
      @input="filterByItem($event)"/>
</template>

<script>
import vSelect from 'vue-select'
export default {
  props: {
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    className: {
      default: ''
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      selected: []
    }
  },
  methods: {
    filterByItem(value) {
      this.$emit('filterByItem', value)
    }
  }
}
</script>

<style lang="scss">
.custom-filter {
  .vs__selected {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    font-size: 14px !important;
    height: 22px !important;
  }
  .vs__search, .vs__search:focus {
    font-size: 14px !important;
    line-height: 1 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    min-height: 30px !important;
  }
  .vs__dropdown-toggle {
    height: 100% !important;
    padding: 0px !important;
  }
  .vs__dropdown-menu {
    li {
      font-size: 14px !important;
      line-height: 1 !important;
    }
  }
  .vs__actions {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
