import axiosIns from '/src/libs/axios'

class TeamsService {
  getTeams(data) {
    return axiosIns.get('teams', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  createTeam(data) {
    return axiosIns.post('teams', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  updateTeam(id, data) {
    return axiosIns.put(`teams/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  deleteTeam(id) {
    return axiosIns.delete(`teams/${id}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  changeStatus(data) {
    return axiosIns.patch('change-manager-status', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new TeamsService();
