import CompanyService from '../services/CompanyService'

export default  {
  namespaced: true,

  state: {
    all: [],
    companies: [],
    pagination: {}
  },

  actions: {
    getCompanies({ commit }, data) {
      return CompanyService.getCompanies(data)
        .then(response => {
          commit('setCompanies', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getAllCompanies({ commit }) {
      return CompanyService.getAllCompanies()
        .then(response => {
          commit('setAll', response.data)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    createCompanyAndAdmin({ dispatch, getters }, data) {
      return CompanyService.createCompanyAndAdmin(data)
        .then(response => {
          dispatch('getCompanies', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeStatus({ commit }, data) {
      return CompanyService.changeStatus(data)
        .then(response => {
          commit('updateStatus', data)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setCompanies(state, companies) {
      state.companies = companies
    },

    setAll(state, companies) {
      state.all = companies
    },

    updateStatus(state, company) {
      state.companies = state.companies.map(el => {
        if (el.company_id == company.id) {
          el.status = company.status
          return el
        }

        return el
      })
    },

    setPagination(state, page){
      state.pagination = page;
    }
  },

  getters: {
    companies(state) {
      return state.companies
    },

    getAllCompanies(state) {
      return state.all
    },

    pagination(state){
      return state.pagination;
    }
  }
}
