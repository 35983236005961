<template>
  <b-form-input v-if="filterOptions.enabled"
    v-model="search"
    trim
    :placeholder="placeholder"
    type="search"
    class="d-inline-block"
    @keyup.enter="handleCustomFilter($event, field)"
    @blur="handleCustomFilter($event, field)"
    @search="reset($event, field)"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue';

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    filterOptions: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    searchValue: {
      type: [String, Number],
    },
  },
  data() {
    return {
      val: ''
    }
  },
  components: {
    BFormInput
  },
  computed: {
   search: {
     get() {
      return this.searchValue
     },
     set(val) {
       return val
     }
   }
  },
  methods: {
    handleCustomFilter($event, fieldName) {
      this.$emit('handleCustomFilter', $event.target.value.trim(), fieldName);
    },
    reset($event, fieldName) {
      if (!$event.target.value) {
        this.$emit('clearColumnFilter', fieldName);
      }
    }
  }
}
</script>
