import axiosIns from '/src/libs/axios'

class CompanyService {
  getCompanies(data) {
    return axiosIns.get('company', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  getAllCompanies() {
    return axiosIns.get('companies')
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  createCompanyAndAdmin(data) {
    return axiosIns.post('company', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  changeStatus(data) {
    return axiosIns.patch('change-company-status', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new CompanyService()
