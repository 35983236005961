import axiosIns from '/src/libs/axios'
const apiName = 'rule-book';

class RuleBookService {
  getRules(data) {
    return axiosIns.get(apiName, {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  setRulesFull(data) {
    return axiosIns.get(apiName + '/quick', {
      params: data
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data)
      }
    })
  }

  createRule(data) {
    return axiosIns.post(apiName, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  updateRule(id, data) {
    return axiosIns.put(`${apiName}/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
  toggleBlockCompletely(id, data) {
    return axiosIns.put(`${apiName}/block-completely/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  deleteRule(id) {
    return axiosIns.delete(`${apiName}/${id}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  getRuleTypes() {
    return axiosIns.get(`${apiName}/create`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data)
      }
    })
  }

}

export default new RuleBookService();
