import axios from 'axios'
import authHeader from './auth-header'
import axiosIns from '/src/libs/axios'
import store from '../store'

const API_URL = process.env.VUE_APP_BACKEND_URL

class AuthService {
  login(user) {
    return axios.post(API_URL + 'auth/login', {
      email: user.email,
      password: user.password
    })
      .then(response => {
        if (!response.data.access_token) {
          throw 'There is not access token';
        }

        return response.data
      })
      .catch(error => {
        console.error(error)
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  checkCode(code) {
    return axios.post(API_URL + 'auth/check-code', {
      code: code
    }, {
      headers: authHeader()
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  forgotPassword(email) {
    return axios.post(API_URL + 'auth/reset-password-request', {
      email: email
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data.message)
        }
      })
  }

  resetPassword(data) {
    return axios.post(API_URL + 'auth/change-password', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  logout() {
    localStorage.removeItem('user')
  }

  getUserInfo() {
    if (localStorage['user'] === undefined) {
      return
    }

    return axiosIns.get('auth/user')
      .then(({data}) => {
        let user = JSON.parse(localStorage['user'])
        user.user_data = data.user_data
        user.notifications = data.notifications
        store.commit('auth/loginSuccess', user)
      })
      .catch(error => {
        console.error(error)
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new AuthService()
