import RuleBookService from '@/services/RuleBookService'

export default {
  namespaced: true,

  state: {
    rules: [],
    pagination: {},
    types: {},
    rulesFull: [],
  },

  actions: {
    getRules({ commit }, data) {
      return RuleBookService.getRules(data)
        .then(response => {
          response.data.items.map(el => {
            el.status = Boolean(el.status);
            el.block_completely = Boolean(el.block_completely);
            return el;
          })
          commit('setAll', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getRulesFull({ commit }, data) {
      return RuleBookService.setRulesFull(data)
      .then(response => {
        commit('setRulesFull', response.data)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
    },

    getRulesTypes({ commit }) {
      return RuleBookService.getRuleTypes()
      .then(response => {
        commit('setTypes', response.data.ticketTypes)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
    },

    createRule({ dispatch, getters, commit }, data) {
      data.status = data.status ? 1 : 0;
      data.block_completely = data.block_completely ? 1 : 0;
      return RuleBookService.createRule(data)
        .then(response => {
          dispatch('getRules', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateRule({ dispatch, getters }, payload) {
      payload.data.status = payload.data.status ? 1 : 0;
      payload.data.block_completely = payload.data.block_completely ? 1 : 0;
      return RuleBookService.updateRule(payload.id, payload.data)
        .then(response => {
          dispatch('getRules', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteRule({ dispatch, getters }, payload) {
      return RuleBookService.deleteRule(payload.id)
        .then(response => {
          dispatch('getRules', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    toggleBlockCompletely({ dispatch, getters }, payload) {
      return RuleBookService.toggleBlockCompletely(payload.id)
      .then(response => {
        dispatch('getRules', getters.pagination)
        return Promise.resolve(response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
    },
  },

  mutations: {
    setAll(state, rules) {
      if (!rules) {return}
      state.rules = rules
    },

    setRulesFull(state, rulesFull) {
      if (!rulesFull) {return}
      state.rulesFull = rulesFull
    },

    setTypes(state, types) {
      if (!types) {return}
      state.types = types
    },

    updateStatus(state, rule) {
      state.rules = state.rules.map(el => {
        if (el.id === rule.id) {
          el.status = rule.status
          return el
        }

        return el
      })
    },

    setPagination(state, page){
      state.pagination = page;
    },

  },

  getters: {
    rules(state) {
      return state.rules
    },

    rulesFull(state) {
      return state.rulesFull
    },

    pagination(state){
      return state.pagination;
    },

    types(state) {
      return state.types;
    }
  }
}
