import AuthService from '../auth/AuthService'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null }

export default {
  namespaced: true,

  state: initialState,

  actions: {
    login({ commit }, user) {
      return AuthService.login(user)
        .then(response => {
          commit('loginSuccess', response)
          return Promise.resolve(response)
        })
        .catch(error => {
          commit('loginFailure')
          return Promise.reject(error)
        })
    },

    checkCode({ commit }, code) {
      return AuthService.checkCode(code)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    forgotPassword({ commit }, email) {
      return AuthService.forgotPassword(email)
        .then(user => {
          return Promise.resolve(user)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    resetPassword({ commit }, data) {
      return AuthService.resetPassword(data)
        .then(user => {
          return Promise.resolve(user)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
  },

  mutations: {
    loginSuccess(state, user) {
      user.user_data.ability.push({ action: 'read', subject: 'Common' })

      state.status.loggedIn = true
      state.user = user
      saveUserToLocalStorage(state)
    },

    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },

    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },

    toggleNotification (state) {
      state.user.user_data.notification_status = !state.user.user_data.notification_status
      saveUserToLocalStorage(state)
    },

    changeRequestVisibleFields (state, fields) {
      state.user.user_data.visible_fields = JSON.stringify(fields)
      saveUserToLocalStorage(state)
    },

    setNotifications (state, newArray) {
      state.user.notifications = [...newArray]
      saveUserToLocalStorage(state)
    },

    readAllNotifications (state) {
      state.user.notifications = [
        ...state.user.notifications.map(el => ({ ...el, status: 1 }))]
      saveUserToLocalStorage(state)
    },
    readOneNotification(state, id) {
      state.user.notifications = [
        ...state.user.notifications.map(el => el.id === id ? { ...el, status: 1 } : el)];
      saveUserToLocalStorage(state)
    },
  },

  getters: {
    userData(state) {
      return state.user ? state.user.user_data : null
    },
    notifications(state) {
      return state.user &&  state.user.notifications ? state.user.notifications : []
    },
  },
}
const saveUserToLocalStorage = (state) => {
  localStorage['user'] = JSON.stringify(state.user)
}
