export default function overrideRulesByConditions(rules) {
    const exitsShowLogsRules = rules.filter((rule) => {
        return (rule.subject === 'request_logs'
             || rule.subject === 'system_logs')
             && rule.action === 'read'
    }).length > 0
    if (exitsShowLogsRules) {
        rules.push({action: 'read', subject: 'logs'})
    }
    return rules
}