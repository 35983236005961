import logService from "@/services/LogService"
import activityLogService from "@/services/ActivityLogService";

export default {
  namespaced: true,

  state: {
    logs: [],
    activityLogs: [],
    logsPagination: {},
  },

  actions: {
    getLogs({ commit }, data) {
      return logService.getLogs(data)
        .then(response => {
          commit('setLogs', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getActivityLogs({ commit }, data) {
      return activityLogService.getLogs(data)
        .then(response => {
          commit('setActivityLogs', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setLogs(state, logs) {
      state.logs = logs
    },

    setActivityLogs(state, logs) {
      state.activityLogs = logs
    },

    setLogsPagination(state, page) {
      state.logsPagination = page
    },
  },

  getters: {
    logs(state) {
      return state.logs
    },

    activityLogs(state) {
      return state.activityLogs
    },

    logsPagination(state) {
      return state.logsPagination
    },
  }
}
