import TeamsService from '../services/TeamsService'

export default {
  namespaced: true,

  state: {
    teams: [],
    pagination: {},
    newTeamMarker: false
  },

  actions: {
    getTeams({ commit }, data) {
      return TeamsService.getTeams(data)
        .then(response => {
          commit('setAll', response.teams.items)
          return Promise.resolve(response.teams)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    createTeam({ dispatch, getters, commit }, data) {
      return TeamsService.createTeam(data)
        .then(response => {
          commit('setNewTeamMarker')
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateTeam({ dispatch, getters }, payload) {
      return TeamsService.updateTeam(payload.id, payload.data)
        .then(response => {
          dispatch('getTeams', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteTeam({ dispatch, getters }, payload) {
      return TeamsService.deleteTeam(payload.id)
        .then(response => {
          dispatch('getTeams', getters.pagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeStatus({ commit }, data) {
      return TeamsService.changeStatus(data)
        .then(response => {
          commit('updateStatus', data)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setAll(state, teams) {
      state.teams = teams
    },

    updateStatus(state, team) {
      state.teams = state.teams.map(el => {
        if (el.id === team.id) {
          el.status = team.status
          return el
        }

        return el
      })
    },

    setPagination(state, page){
      state.pagination = page;
    },

    setNewTeamMarker(state) {
      state.newTeamMarker = ! state.newTeamMarker
    }
  },

  getters: {
    teams(state) {
      return state.teams
    },

    pagination(state){
      return state.pagination;
    },

    newTeamMarker(state) {
      return state.newTeamMarker
    }
  }
}
