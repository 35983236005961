export default [
  {
    path: '/',
    name: 'requests',
    component: () => import('@/views/apps/requests/Requests.vue'),
    meta: {
      resource: 'requests',
      action: 'read',
    },
  },
  {
    path: '/rule-book',
    name: 'ruleBook',
    component: () => import('@/views/apps/rule-book/RuleBook.vue'),
    meta: {
      resource: 'rule_book',
      action: 'read',
    },
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/apps/log/Logs.vue'),
    meta: {
      resource: 'logs',
      action: 'read',
    },
  },
  {
    path: '/teams',
    name: 'apps-teams-list',
    component: () => import('@/views/apps/teams/Teams.vue'),
    meta: {
      resource: 'team',
      action: 'read',
    },
  },
  {
    path: '/companies',
    name: 'apps-companies-list',
    component: () => import('@/views/apps/company/company-list/CompaniesList'),
    meta: {
      resource: 'companies',
      action: 'read',
    },
  },
  {
    path: '/download-extension',
    name: 'apps-download-extension',
    component: () => import('@/views/apps/download-extension/Index'),
    meta: {
      resource: 'requests',
      action: 'add',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/apps/reports/Reports'),
    meta: {
      resource: 'report',
      action: 'read',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/apps/notifications/Notifications'),
    meta: {
      resource: 'Common',
      action: 'read',
    },
  },
]
