import axiosIns from '/src/libs/axios'

class RequestService {
  getRequests(data) {
    return axiosIns.get('extension/request', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  updateRequest(id, data) {
    return axiosIns.patch(`extension/request/${id}`, data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        if (response) {
          return Promise.reject(response.data)
        }
      })
  }

  updateRequests(ids, type) {
    return axiosIns.post('extension/update-requests', {
      ids,
      type,
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }
}

export default new RequestService()
