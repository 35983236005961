import axiosIns from '/src/libs/axios'

class UserService {
  getUsers(data) {
    return axiosIns.get('user', {
      params: data
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  createUser(data) {
    return axiosIns.post('user', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  updateUser(id, data) {
    return axiosIns.patch(`user/${id}`, data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  updateUserName(data) {
    return axiosIns.patch('change-user-name', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  changeStatus(data) {
    return axiosIns.patch('change-user-status', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data)
        }
      })
  }

  changeNotificationStatus(data) {
    return axiosIns.patch('toggle-notification', data)
  }

  updateRequestVisibleFields(data) {
    return axiosIns.patch('change-visible-fields', data)
  }

  constructor () {
    this.notifications = {
      clearAll ($store) {
        $store.commit('auth/setNotifications', [])
        axiosIns.patch('notification/clear-all')
      },
      readAll ($store) {
        $store.commit('auth/readAllNotifications')
        axiosIns.patch('notification/mark-all-as-read')
      },
      readOne ($store, id) {
        $store.commit('auth/readOneNotification', id)
        axiosIns.patch(`notification/mark-as-read/${id}`)
      },
    }
  }
}

export default new UserService();
