import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import overrideRulesByConditions from '@/libs/acl/overrideRulesByConditions'

if (localStorage.getItem('user') === 'undefined') {
    localStorage.removeItem('user')
}
const userData = JSON.parse(localStorage.getItem('user'))

const existingAbility = userData ? userData.user_data.ability : null

const rules = existingAbility || initialAbility


export default new Ability(overrideRulesByConditions(rules))
