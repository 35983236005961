import ScheduleService from '../services/ScheduleService';

export default {
  namespaced: true,

  state: {
    currentSchedules: [],
    expiredSchedules: [],
    currentSchedulesPagination: {},
    expiredSchedulesPagination: {},
  },

  actions: {
    getCurrentList({ commit }, data) {
      data.type = 'current';
      return ScheduleService.getList(data)
        .then(response => {
          commit('setCurrent', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    getExpiredList({ commit }, data) {
      data.type = 'expired';
      return ScheduleService.getList(data)
        .then(response => {
          commit('setExpired', response.data.items)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    import({ dispatch, getters }, data) {
      return ScheduleService.import(data)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
        .finally(() => {
          dispatch('getCurrentList', getters.currentSchedulesPagination);
        })
    },

    create({ dispatch, getters }, data) {
      return ScheduleService.create(data)
        .then(response => {
          dispatch('getCurrentList', getters.currentSchedulesPagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    update({ dispatch, getters }, payload) {
      return ScheduleService.update(payload.id, payload.data)
        .then(response => {
          dispatch('getCurrentList', getters.currentSchedulesPagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    delete({ dispatch, getters }, payload) {
      return ScheduleService.delete(payload.id)
        .then(response => {
          dispatch('getCurrentList', getters.currentSchedulesPagination)
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setCurrent(state, schedules) {
      state.currentSchedules = schedules;
    },

    setExpired(state, schedules) {
      state.expiredSchedules = schedules;
    },

    setCurrentSchedulePagination(state, page) {
      state.currentSchedulesPagination = page;
    },

    setExpiredSchedulePagination(state, page) {
      state.expiredSchedulesPagination = page;
    },
  },

  getters: {
    currentSchedules(state) {
      return state.currentSchedules;
    },

    expiredSchedules(state) {
      return state.expiredSchedules;
    },

    currentSchedulesPagination(state) {
      return state.currentSchedulesPagination;
    },

    expiredSchedulesPagination(state) {
      return state.expiredSchedulesPagination;
    },
  }
}
