export default {
  namespaced: true,

  state: {
    tab: ''
  },

  mutations: {
    setTab(state, tab) {
      state.tab = tab
    },
  },

  getters: {
    tab(state) {
      return state.tab
    },
  }
}
