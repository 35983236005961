import axiosIns from '/src/libs/axios'

class ScheduleService {

  getList(data) {
    return axiosIns.get('schedule', {
      params: data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      })
  }

  import(data) {
    return axiosIns.post('schedule/import', data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      })
  }

  getOne(id) {
    return axiosIns.get(`schedule/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      });
  }

  create(data) {
    return axiosIns.post('schedule', data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      })
  }

  update(id, data) {
    return axiosIns.patch(`schedule/${id}`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      })
  }

  delete(id) {
    return axiosIns.delete(`schedule/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      })
  }
}

export default new ScheduleService();
